import { useEffect } from "react";

const RedirectToExternal = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null; // Prevent rendering anything while redirecting
};

export default RedirectToExternal;
