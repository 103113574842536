import React, { useEffect } from "react";
import {
  Navigate,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { privateRoutes, publicRoutes, RouteNames } from "./routes";
import { useTypedSelector } from "./hooks/useTypedSelector";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { createBrowserRouter } from "react-router-dom";
import ErrorPage404 from "./pages/ErrorPage404/ErrorPage404";
import PublicRoute from "./components/PublicRoute/PublicRoute";
import { useActions } from "./hooks/useActions";
import RedirectToExternal from "./components/ExternalRoute/RedirectToExternal";

const NavigateToSubscriptionFromPublicRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setLastRouteBeforeRedirect } = useActions();

  useEffect(() => {
    setLastRouteBeforeRedirect(location.pathname);
    navigate(RouteNames.REGISTRATION, { replace: true });
  }, []);

  return null;
};

const NavigateToOtherRoutesDefaultForPrivateRoutes = () => {
  const navigate = useNavigate();
  const { lastRouteBeforeRedirect } = useTypedSelector((state) => state.auth);
  const { setLastRouteBeforeRedirect } = useActions();
  useEffect(() => {
    if (lastRouteBeforeRedirect === RouteNames.SUBSCRIPTION_TYPE) {
      setLastRouteBeforeRedirect("");
      navigate(RouteNames.SUBSCRIPTION_TYPE, { replace: true });
    } else {
      navigate(RouteNames.ACCOUNTS, { replace: true });
    }
  }, []);

  return null;
};

const AppRouter: React.FC = () => {
  const { isAuth } = useTypedSelector((state) => state.auth);

  const router = createBrowserRouter(
    isAuth
      ? [
          ...privateRoutes.map((route) => {
            return {
              path: route.path,
              element: (
                <PrivateRoute>
                  <route.component />
                </PrivateRoute>
              ),
              errorElement: <ErrorPage404 />,
            };
          }),
          {
            path: "*",
            element: <NavigateToOtherRoutesDefaultForPrivateRoutes />,
          },
        ]
      : [
          {
            path: "/", // Redirect root to an external URL
            element: (
              <RedirectToExternal url="https://aimap.aimarketing.info" />
            ),
          },
          ...publicRoutes.map((route) => {
            return {
              path: route.path,
              element: (
                <PublicRoute>
                  <route.component />
                </PublicRoute>
              ),
              errorElement: <ErrorPage404 />,
            };
          }),
          {
            path: RouteNames.SUBSCRIPTION_TYPE,
            element: <NavigateToSubscriptionFromPublicRoute />,
          },
          {
            path: "*",
            element: <Navigate to={RouteNames.AIMAP} replace />,
          },
        ]
  );

  return <RouterProvider router={router} />;
};

export default AppRouter;
